import { forwardRef } from "react";

interface AnchorLinkTargetProps {
  target?: string;
  children: any;
}

const AnchorLinkTarget = forwardRef(
  ({ target, children }: AnchorLinkTargetProps, ref: any) => (
    <div id={target} ref={ref}>
      {children}
    </div>
  )
);

export default AnchorLinkTarget;
